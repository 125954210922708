import React from "react";
import { viewports, colors } from "../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller, mdOrBigger } = viewports;
const { white, black, primary } = colors;

const NewMediaStyles = () => (
  <style jsx>{`
    .new-media {
      padding: 64px 32px;
      margin: 100px 0 72px 0;
      position: relative;
      width: 100%;
    }
    .force-abs {
      position: absolute !important;
    }
    .special-cursor:hover {
      cursor: pointer;
    }
    .new-media-circle {
      position: absolute;
      right: -30%;
      width: 50%;
      top: -110%;
    }
    .new-media-shadow {
      position: absolute;
      top: -10%;
      width: 100%;
    }
    .new-media-title {
      font-size: 72px;
      text-align: center;
      font-weight: 800;
      margin-bottom: 0;
    }
    .new-media-p {
      color: #596779;
      font-size: 20px;
    }
    .news-cta {
      display: table;
      margin: 0 auto;
      padding: 8px 16px;
      color: #fff;
      border-radius: 32px;
      border: none;
      background: #1769ff;
      font-weight: 800;
    }
    .download-section {
      padding: 100px;
      background: #090d12;
      border-radius: 32px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      margin: 40px 32px;
    }
    .download-title {
      font-size: 72px;
      color: #fff;
      font-weight: 800;
      line-height: 1.2;
    }
    .download-p {
      color: #d6dadf;
      font-size: 20px;
      margin-bottom: 40px;
    }
    .download-cta {
      padding: 12px 24px;
      color: #fff;
      border-radius: 32px;
      border: none;
      background: #1769ff;
      font-weight: 800;
    }
    .download-white {
      position: absolute;
      width: 250px;
      height: 250px;
      background: #013f99;
      border-radius: 250px;
      top: -125px;
      left: 50%;
      transform: translate(-50%, -25px);
    }
    .download-blue {
      position: absolute;
      width: 250px;
      height: 250px;
      background: transparent;
      border: 1px solid #1769ff;
      border-radius: 250px;
      bottom: -125px;
      left: -125px;
    }

    .download-card {
      background: #fff;
      padding: 32px;
      position: relative;
      border-radius: 24px;
      margin-bottom: 60px;
    }
    .periwinkle {
      background: #ccdefe;
    }
    .skyblue {
      background: #ccecfe;
    }
    .special-blue {
      background: #ccfefb !important;
    }
    .download-card-shadow {
      position: absolute;
      right: -16px;
      top: 24px;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 24px;
      content: " ";
      border: 1px solid #1769ff;
    }
    .download-card-title {
      color: #090d12;
      font-weight: 800;
      font-size: 24px;
    }

    .specialty-blog {
      padding-bottom: 160px;
    }
    .specialty-blog-title {
      font-size: 72px;
      font-weight: 800;
    }
    .specialty-blog__date {
      padding-top: 24px;
      color: #748093;
      font-size: 16px;
    }
    .specialty-blog__title {
      color: #090d12;
      font-weight: 800;
      font-size: 24px;
    }
    .single-story-image-b {
      border-radius: 32px;
      width: 100%;
      height: 300px;
      object-fit: cover;
    }

    .single-story {
      padding: 16px;
      padding-bottom: 60px;
    }
    .single-story-small {
      color: #090d12;
      font-size: 20px !important;
    }
    .single-story-title {
      color: #090d12;
      font-size: 42px;
      line-height: 1.2;
      font-weight: 800;
      margin-top: 32px;
      margin-bottom: 24px;
      display: block;
    }

    .single-story-date {
      color: #748093;
      font-size: 16px;
    }
    .single-story-cont {
      border-radius: 32px;
      position: relative;
    }
    .single-story-image {
      border-radius: 32px;
      width: 100%;
      height: 540px;
      object-fit: cover;
    }
    .single-story-image-b {
      border-radius: 32px;
      width: 100%;
      height: 280px;
      object-fit: cover;
    }
    .blog-spacing {
      margin-top: 64px;
    }
    .single-story-chip {
      position: absolute;
      right: 0;
      bottom: 40px;
      color: ${white};
      background-color: ${primary};
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      padding: 12px;
    }
    .visual-links {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .visual-links-title {
      font-size: 72px;
      color: #090d12;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 24px;
      text-align: left;
    }
    .business-types-custom {
      width: 60%;
      margin: 0 60px;
    }
    .visual-link {
      position: relative !important;
    }
    .visual-link-image {
      position: relative;
      border-radius: 32px;
    }
    .visual-link-title {
      font-size: 24px;
      color: #fff;
      font-weight: 800;
      position: absolute;
      bottom: 21px;
      left: 32px;
      white-space: pre-line;
      line-height: 1.2;
    }
    .visual-link-p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 160%;
      color: #748093;
    }
    .visual-link-link {
      background: #e2e4e9;
      padding: 8px 20px;
      border-radius: 32px;
      position: absolute;
      right: 32px;
      bottom: 48px;
    }
    .video-media {
      background: #090d12;
      padding: 100px 0 150px 0;
      margin-top: 60px;
    }
    .video-media-cont {
      margin-top: 60px;
    }
    .video-slider-poster {
      position: relative;
      margin: 16px;
      width: 100%;
    }
    .video-slider-title {
      color: #fff;
      font-weight: 800;
      font-size: 32px;
      max-width: 60%;
      position: absolute;
      bottom: 32px;
      left: 32px;
      z-index: 9;
    }
    .video-slider-poster-img {
      position: relative;
    }
    .video-slider-poster-img:before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      z-index: 1;
      left: 0;
    }
    .video-media-container .products__carousel .slider-control-centerright {
      top: -150px !important;
    }
    .video-section__yt {
      transform: translateY(10%);
    }
    .video-slider-btn {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      position: absolute;
      right: 32px;
      bottom: 72px;
      background: transparent;
      border: solid 1px #fff;
      z-index: 9;
    }
    .video-slider-btn img {
      margin-left: -4px;
    }
    .video-slider-btn:hover {
      cursor: pointer;
    }
    .special-margin {
      margin: 0 60px;
    }
    @media screen and (max-width: 1024px) {
      .download-section {
        margin: 40px 32px;
      }
      .video-slider-title {
        font-size: 28px;
      }
      .specialty-blog {
        margin: 0 32px;
      }
      .single-story {
        margin: 0 16px;
      }
      .visual-links {
        margin: 0 32px;
      }
      .new-media-title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
      }
      .video-media {
        margin-bottom: 32px;
        padding: 100px 0;
      }
      .download-title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
      }
      .visual-links-title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
      }
    }
    @media ${mdOrSmaller} {
      .new-media {
        padding: 64px 32px;
        margin: 100px 0 50px 0;
      }
      .video-slider-btn {
        bottom: 48px;
      }
      .video-section__yt {
        transform: translateY(0);
      }
      .new-media-title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
      }
      .download-section {
        margin: 40px 16px !important;
        padding: 64px;
      }
      .no-gutter {
        row-gap: 0 !important;
      }
      .download-card-cont {
        margin-top: 60px;
      }
      .download-title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
      }
      .video-media {
        margin-bottom: 32px;
        padding: 100px 0;
      }
      .video-media-cont {
        padding-top: 0px;
        margin-top: 0px;
      }
      .visual-links-title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
      }
      .visual-link-link {
        left: 32px;
        width: 25%;
        bottom: 24px;
      }
      .business-types-custom {
        width: auto;
        margin: 0 16px;
      }
      .special-margin {
        margin: 32px 0 0 0;
      }
      .video-slider-title {
        font-size: 24px;
      }
      .new-video-title {
        margin-bottom: 32px;
      }
    }
    @media ${smOrSmaller} {
      .special-margin {
        margin: 0;
      }
      .video-slider-poster {
        margin: 0;
      }
      .new-media {
        padding: 32px 16px;
      }
      .new-media-title,
      .visual-links-title {
        font-size: 42px !important;
        line-height: 1.3;
      }
      .download-section {
        margin: 0 !important;
        padding: 32px;
      }
      .download-card {
        min-width: 300px;
      }
      .download-title {
        font-size: 42px;
        margin-top: 64px;
      }
      .download-cta {
        margin-bottom: 48px !important;
      }
      .specialty-blog {
        margin: 16px;
        padding-bottom: 80px;
      }
      .specialty-blog-title {
        font-size: 42px;
      }
      .single-story {
        margin: 0;
      }
      .visual-links {
        margin: 0 16px;
      }
      .visual-link-image {
        max-width: 100%;
        min-width: 280px;
        position: static;
      }
      .visual-link-title {
        max-width: 50%;
      }
      .blog-spacing {
        margin-top: 0;
      }
      .video-media {
        padding: 32px 16px;
      }
      .video-media-cont {
        margin-top: 32px;
      }
      .video-slider-title {
        font-size: 16px;
        bottom: 16px;
        left: 16px;
      }
      .video-slider-btn {
        width: 48px;
        height: 48px;
        bottom: 24px;
        right: 16px;
      }
      .video-slider-btn-img {
        margin: 4px 5px 6px 10px !important;
      }
    }
    @media screen and (max-width: 320px) {
      .download-card {
        min-width: 250px;
      }
    }
  `}</style>
);

export default NewMediaStyles;

import React from "react";
import { string, bool, oneOf } from "prop-types";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Image from "../../components/ImageQuerys/HeroImages";

import ogImage from "../../images/global_assets/og-image.png";
import chevron from "../../images/svg/chevron_left.svg";
import HeadingBuilder from "../../components-v2/Base/HeadingBuilder";
import NewMediaStyles from "./new-media-styles";

const ProductPhotos = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Media"
        description="Find out the lastest news about SpotOn and contact us for press inqueries"
        image={`https://spoton.com/${ogImage}`}
      />
      <section className="new-media z-0 text-center">
        <HeadingBuilder
          level={1}
          injectionType={1}
          withBlueSymbol
          symbol="."
          className="new-media-title z-20 whitespace-pre"
        >
          Photography
        </HeadingBuilder>

        <Image
          className="absolute force-abs right-0 top-[-75px] lg:top-[-50px] w-[125px] lg:w-[175px]"
          imageName="custom-circle.png"
        />
        <Image
          className="new-media-shadow z-[-10]"
          imageName="fake-shadow.png"
        />
      </section>
      <section className="executive-headshots mt-[10]" />
      <NewMediaStyles />
    </Layout>
  );
};

export default ProductPhotos;
